import React from "react"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

const Alert = styled.div`
	${tw`flex bg-blue-200 border-l-8 rounded-md border-blue-600 my-8 p-4`}
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-blue-600 text-2xl md:text-4xl float-left`}
`

const Title = styled.h3`
	${tw`text-black`}
`

const Message = styled.p`
	${tw`text-black text-lg`}
`

const InfoAlert = ({ title, children }) => {
	return (
		<Alert>
			<StyledFontAwesomeIcon icon={faInfoCircle} />
			<div>
				<Title>{title}</Title>
				<Message>{children}</Message>
			</div>
		</Alert>
	)
}
	
export default InfoAlert