import React from "react"
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import tw, { styled } from "twin.macro"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faSitemap, faTags } from "@fortawesome/free-solid-svg-icons"
import { faBookReader } from "@fortawesome/free-solid-svg-icons"

const Wrapper = styled.section`
    ${tw`w-full m-0 p-0 bg-psb-dark-gray-400 text-white`}
`

const Container = styled.div`
    ${tw`container flex flex-row items-center w-11/12 mx-auto px-5 py-8`}
`

const Left = styled.div`
    ${tw`lg:w-2/3`}
`

const Title = styled.h1`
    ${tw`text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl`}
`

const Category = styled.p`
    ${tw`text-white text-lg font-semibold`}
`

const Date = styled.div`
    ${tw`text-psb-orange-400 font-medium`}
`

const Right = styled.div`
    ${tw`lg:w-1/3 hidden lg:flex lg:flex-row lg:justify-end lg:self-center`}
`

const Aside = styled.div`
    ${tw`mx-2 px-3 py-3 rounded-lg shadow-lg bg-psb-dark-light-gray-400 font-medium leading-relaxed`}
`

const StyledLink = styled(Link)`
    ${tw`text-white`}
`

const StyledUnderlineLink = styled(Link)`
    ${tw`text-white underline`}
`

const TagList = styled.div`
    ${tw`pl-2 space-x-2`}
`

const Tag = styled.span`
    ${tw``}
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`text-psb-orange-400 text-xl`}
`

const SocialShareList = styled.div`
    ${tw`mt-2`}
`

const SocialShare = styled.span`
    ${tw`pl-2 py-4`}
`

const ArticleHero = ({  title, date, categories, tags, readingtime, pathname }) => {
    return(
        <Wrapper>
            <Container>
                <Left>
                    <Title>{title}</Title>
                    <Category>
                        <StyledFontAwesomeIcon icon={faSitemap} />&nbsp;&nbsp;
                        {categories.map((category, index, array) => {
                            return (
                                <StyledUnderlineLink to={`/category/${kebabCase(category)}`}>
                                    {category.toUpperCase()}{ index < array.length - 1 ? ", " : "" }
                                    </StyledUnderlineLink>
                            )
                        })}
                    </Category>
                    <Date>Updated on: {date}</Date>
                </Left>
                <Right>
                    <Aside>
                        <TagList>
                            <StyledFontAwesomeIcon icon={faTags} />
                            {tags.map((tag, index, array) => {
                                return (
                                    <Tag>
                                        <StyledUnderlineLink to={`/tag/${kebabCase(tag)}`}>
                                            {tag}{ index < array.length - 1 ? "," : "" }
                                        </StyledUnderlineLink>
                                    </Tag>
                                )
                            })}
                        </TagList>
                        <SocialShareList>
                            <div>
                                <SocialShare>
                                    <StyledLink to={`https://twitter.com/intent/tweet?url=https://pocketshutterbug.com${pathname}&text=${title}&via=pshutterbug`}>
                                        <StyledFontAwesomeIcon icon={faTwitter} /> Share on Twitter
                                    </StyledLink>
                                </SocialShare>   
                            </div>
                            <div>             
                                <SocialShare>
                                    <StyledLink to={`https://www.facebook.com/sharer.php?u=https://pocketshutterbug.com${pathname}`}>
                                        <StyledFontAwesomeIcon icon={faFacebook} /> Share on Facebook
                                    </StyledLink>
                                </SocialShare>
                            </div>
                        </SocialShareList>
                        <SocialShareList>
                            <div>
                                <SocialShare>
                                    <StyledFontAwesomeIcon icon={faBookReader} /><SocialShare>{readingtime}</SocialShare>
                                </SocialShare>
                            </div>
                        </SocialShareList>
                    </Aside>
                </Right>
            </Container>
        </Wrapper>
    )
}

export default ArticleHero