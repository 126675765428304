import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleHero from "../components/article/article-hero"
import Section from "../components/section/section"
import SectionSubscribe from "../components/section/section-subscribe"

import AffiliateAlert from "../components/article/affiliate-alert"
import InfoAlert from "../components/article/info-alert"
import DangerAlert from "../components/article/danger-alert"
import WarningAlert from "../components/article/warning-alert"
import SuccessAlert from "../components/article/success-alert"
import ProductSummary from "../components/guide/product-summary"
import ProductSpecifications from "../components/guide/product-specifications"
import ProductList from "../components/guide/product-list"

const shortcodes = {
    AffiliateAlert,
    SuccessAlert,
    WarningAlert,
    InfoAlert,
    DangerAlert,
    ProductSummary,
    ProductSpecifications,
    ProductList
}

const ArticleWrap = styled.div`
    ${tw`container flex flex-col items-center`}
`

const ArticleContent = styled.article`
    ${tw`max-w-4xl`}
`

const Description = styled.p`
    ${tw`leading-relaxed my-3 font-medium`}
`

const Separator = styled.hr`
    ${tw`w-24 rounded-md border-2 border-psb-orange-400`}
`

const Content = styled.div`
    ${tw`mt-4`}
`

const ArticleTemplate = ({ data, location }) => {
    const post = data.mdx
    let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

    return (
        <Layout>
            <SEO
                title={post.frontmatter.title}
                description={post.description || post.excerpt}
                image={featuredImgFluid}
                pathname={location.pathname}
            />
            <ArticleHero
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                categories={post.frontmatter.categories}
                tags={post.frontmatter.tags}
                readingtime={post.fields.readingTime.text}
                pathname={location.pathname}
            />
            <Section>
			    <ArticleWrap>
                    <ArticleContent>
                        <Img fluid={featuredImgFluid} />
                        <figcaption><strong>{post.frontmatter.featureAttribution}</strong>&emsp;(Source: {post.frontmatter.featureAttributionSource})</figcaption>
                        <Description>{post.frontmatter.description}</Description>
                        <Separator />
                        <Content>
                            <MDXProvider components={shortcodes}>
                                <MDXRenderer>{post.body}</MDXRenderer>
                            </MDXProvider>
                        </Content>
                    </ArticleContent>
			    </ArticleWrap>
            </Section>
        </Layout>
    )
}

export default ArticleTemplate

export const query = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        mdx(fields: { slug: { eq: $slug } }) {
            body
            excerpt(pruneLength: 160)
            frontmatter {
                title
                description
                date(formatString: "DD MMMM, YYYY")
                categories
                tags
                featureAttribution
                featureAttributionSource
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 1200 quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`